const formatInputNumber = amount => {
  if (!amount) return '';
  const parts = amount.toString().split('.');
  const comma = ',';

  parts[0] = parts[0]
    .toString()
    .replace(/\D*/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, comma);

  amount = `$${parts.join('.')}`;

  return amount;
};

export default formatInputNumber;
