import React, { useCallback, useState } from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import formatInputNumber from '../../utils/formatInputNumber';
import FormInput from '../shared/FormControl/Input';

const ROIForm = ({ onNext }) => {
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [invoicesPerWeek, setInvoicesPerWeek] = useState('');
  const [invoiceAmountError, setInvoiceAmountError] = useState('');
  const [invoicesPerWeekError, setInvoicesPerWeekError] = useState('');
  const [error, setError] = useState('');

  const handleInvoiceAmountChange = useCallback(value => {
    !/^\$\d+(?:,\d+)*(?:.\d{2})?$/.test(value.trim())
      ? setInvoiceAmountError('Invalid amount!')
      : setInvoiceAmountError('');

    setInvoiceAmount(value);
  }, []);

  const handleinvoicesPerWeekChange = useCallback(({ target }) => {
    !/^[0-9]+$/.test(target.value.trim())
      ? setInvoicesPerWeekError('Invalid number!')
      : setInvoicesPerWeekError('');

    setInvoicesPerWeek(target.value);
  }, []);

  const handleNext = useCallback(() => {
    if (invoiceAmount && invoicesPerWeek) {
      setError('');
      onNext(
        parseFloat(invoiceAmount.replace(/\$|,/g, '')),
        parseInt(invoicesPerWeek, 10)
      );
    } else {
      setError('Please complete all the required fields!');
    }
  }, [onNext, invoiceAmount, invoicesPerWeek]);

  return (
    <div className="roi-calculator">
      <h2 className="form-title">ROI Calculator</h2>

      <div className="form-content">
        <label>Average repair order?</label>
        <FormInput
          error={invoiceAmountError}
          onChange={event =>
            handleInvoiceAmountChange(formatInputNumber(event.target.value))
          }
          placeholder="Select one"
          value={invoiceAmount}
        />

        <label htmlFor="invoices-per-week">
          Average number of paid invoices per week?
        </label>
        <FormInput
          error={invoicesPerWeekError}
          id="invoices-per-week"
          name="invoices-per-week"
          onChange={handleinvoicesPerWeekChange}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              handleNext();
            }
          }}
          placeholder="Enter number of invoices"
          type="tel"
          value={invoicesPerWeek.replace(/\D*/g, '')}
        />

        {error && <p className="text-danger fs-16">{error}</p>}

        <CallToAction
          className="w-100"
          customHandleClick={handleNext}
          size="lg"
          value="Next"
          variant="secondary"
        />
      </div>
    </div>
  );
};

export default ROIForm;
